@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100&display=swap');

html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../public/wall.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  background-position: top;
  background-color: #6b6b6b;
}

.App {
  text-align: center;
  margin: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

.race {
  padding: 0.5em;
  color: white;
  margin: 0.6em;
  text-align: right;
  font-weight: 300;
}

.race h2 {
  font-weight: 300;
}

.race .mtp {
  color: yellow;
}

.bet-item {
  border: 1px solid #000;
  border-radius: 0;
  color: white;
  background: rgb(0 0 0 / 50%);
}

.noBetsP {
  color: white;
}

.fair-odds {
  color: white;
  font-weight: 200;
}

.no-user-select {
  user-select: none;
  -webkit-user-select: none;
}

.bet-item-title {
  font-family: 'Roboto', system-ui;
  font-weight: 700;
  font-style: normal;
  font-size: large;
}

.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 80%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  user-select: none;
}
