@tailwind base;
@tailwind components;
@tailwind utilities;

.app-container {
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Roboto', system-ui;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  font-weight: bold;
}

.balance {
  color: #6cec48;
  font-weight: 300;
}

.text-light-green {
  color: #6cec48;
}

.bottom-buttons-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000; /* Ensure buttons are on top of other content */
}

.bottom-button {
  border: solid white thin;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 0px;
  cursor: pointer;
}

.bottom-button:hover {
  background-color: black;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
